import Image from "next/image"
import Link from "next/link"
import { memo, useMemo } from "react"

import { SpaceAndCreator, SpaceMetadata } from "@spatialsys/js/sapi/types"
import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import { useGetCategorySpacesQuery } from "@spatialsys/react/query-hooks/spaces"
import { formatSpacePath } from "@spatialsys/url-utils"
import Config from "@spatialsys/web/config"
import { LoveCount } from "@spatialsys/web/core/js/components/social-signals/love-count"
import { ViewCount } from "@spatialsys/web/core/js/components/social-signals/view-count"
import { spatialCdnImageLoader } from "@spatialsys/web/core/js/util/next-image-loader"
import { Text, cn } from "@spatialsys/web/ui"

const CATEGORY_SLUG = "authless-featured-spaces"
const CATEGORY_SPACE_COUNT = 10
const CATEGORY_CACHE_AND_STALE_TIME = 5 * 1000 * 60

export const ExploreMarquee = memo(function ExploreMarquee() {
  const getCategorySpacesQuery = useGetCategorySpacesQuery(
    { category: CATEGORY_SLUG, count: CATEGORY_SPACE_COUNT },
    {
      // Set a stale time of 5 minutes (default), so that the queries are eventually GC'ed
      cacheTime: CATEGORY_CACHE_AND_STALE_TIME,
      // Set stale time same as cache time. So that if you navigate back/forward with browser, we don't refetch
      staleTime: CATEGORY_CACHE_AND_STALE_TIME,
    }
  )

  const featuredSpacesData = useMemo(() => {
    return getCategorySpacesQuery.data?.pages[0].results.length
      ? {
          firstRow: getCategorySpacesQuery.data?.pages[0].results.slice(
            0,
            Math.ceil(getCategorySpacesQuery.data?.pages[0].results.length / 2)
          ),
          secondRow: getCategorySpacesQuery.data?.pages[0].results.slice(
            Math.ceil(getCategorySpacesQuery.data?.pages[0].results.length / 2)
          ),
        }
      : { firstRow: [], secondRow: [] }
  }, [getCategorySpacesQuery.data?.pages])

  return featuredSpacesData.firstRow?.length > 0 || featuredSpacesData.secondRow?.length > 0 ? (
    <div className="group flex flex-col gap-2 sm:gap-4">
      {featuredSpacesData.firstRow.length > 0 && <ExploreMarqueeRow rowItems={featuredSpacesData.firstRow} />}
      {featuredSpacesData.secondRow.length > 0 && (
        <ExploreMarqueeRow rowItems={featuredSpacesData.secondRow} isReverse />
      )}
    </div>
  ) : (
    // Render placeholder static image if an error occurs while fetching the featured spaces
    <div className="relative mx-auto flex aspect-[3.3] h-[336px] max-w-full items-center overflow-hidden px-8 sm:h-[480px] sm:px-24">
      <div className="absolute inset-y-0 left-8 z-10 w-8 bg-gradient-to-r from-background to-transparent xs:w-12 sm:left-24 sm:w-40 md:w-60 lg:w-96" />
      <img
        src={`${Config.PUBLIC_ASSETS_BASE_URL}/homepage/images/rebranding/explore-marquee/placeholder.webp`}
        alt="placeholder"
        className="size-full object-cover"
      />
      <div className="absolute inset-y-0 right-8 z-10 w-8 bg-gradient-to-l from-background to-transparent xs:w-12 sm:right-24 sm:w-40 md:w-60 lg:w-96" />
    </div>
  )
})

type ExploreMarqueeRowProps = {
  rowItems: SpaceAndCreator[]
  isReverse?: boolean
}

const ExploreMarqueeRow = memo(function ExploreMarqueeRow({ rowItems, isReverse }: ExploreMarqueeRowProps) {
  return (
    <div className="relative flex h-[168px] w-[100vw] max-w-full items-center overflow-hidden sm:h-[240px]">
      <div className="pointer-events-none absolute inset-y-0 left-0 z-10 w-32 bg-gradient-to-r from-background via-background/80 via-30% to-transparent sm:w-48" />
      <div className="absolute flex h-[168px] w-max sm:h-[240px]">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            aria-hidden={index > 0}
            className={cn(
              isReverse ? "animate-marquee-reverse" : "animate-marquee",
              "duration-3500 sm:duration-4000 group-hover:paused"
            )}
          >
            {rowItems.map(({ space }) => (
              <ExploreMarqueeItem key={`${space.id}-${index}`} space={space} isReverse={isReverse} />
            ))}
          </div>
        ))}
      </div>
      <div className="pointer-events-none absolute inset-y-0 right-0 z-10 w-32 bg-gradient-to-l from-background via-background/80 via-30% to-transparent sm:w-48" />
    </div>
  )
})

const ExploreMarqueeItem = memo(function ExploreMarqueeItem({ space }: { space: SpaceMetadata; isReverse?: boolean }) {
  const [thumbnailHovered, setThumbnailHovered] = useBoolean(false)
  const spacePath = formatSpacePath({ id: space.id, slug: space.slug, shareId: space.shareID })

  return (
    <Link
      href={spacePath}
      target="_blank"
      className="group/item relative mx-1 inline-block aspect-[1.66] h-[168px] overflow-hidden rounded-3xl sm:mx-2 sm:h-[240px]"
      onMouseEnter={setThumbnailHovered.setTrue}
      onMouseLeave={setThumbnailHovered.setFalse}
      onFocus={setThumbnailHovered.setTrue}
      onBlur={setThumbnailHovered.setFalse}
    >
      <Image
        alt={space.name}
        src={space.thumbnail}
        loading="lazy"
        className="size-full transition-transform duration-500 group-hover/item:scale-110"
        fill
        loader={spatialCdnImageLoader}
        unoptimized
      />
      {thumbnailHovered && space.customVideo?.mp4 && (
        <div className="absolute inset-0">
          <video src={space.customVideo?.mp4} autoPlay loop muted playsInline className="size-[100.1%] object-cover" />
        </div>
      )}
      <div className="absolute bottom-0 left-0 h-12 w-full bg-gradient-to-t from-black/80 via-black/50 via-50% to-transparent">
        <Text size="sm" className="absolute bottom-3 left-5 font-demibold text-white">
          <ViewCount numViews={space.joinCount} />
          <span className="px-0.5">•</span>
          <LoveCount numLoves={space.likeCount} />
        </Text>
      </div>
    </Link>
  )
})
